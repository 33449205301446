<template>
  <div>
    <page-header
      has-return
      :page="{
        icon: 'fas fa-file-invoice-dollar',
        title: 'Facturación de Reembolso y Pago Directo',
        routes: ['Inicio', 'Facturación de Reembolso y Pago Directo'],
      }"
    />
  </div>
</template>

<script>
import PageHeader from 'PageHeader';

export default {
  name: 'PayRefund',
  components: { PageHeader },
};
</script>
